/**
 * Copyright 2015-present Isshoikka. All Rights Reserved.
 *
 */

const IsshoikkaImageActionConstants = {
    LOAD_BY_ARRANGEMENT: "load_by_arrangement",
};

export default IsshoikkaImageActionConstants;
