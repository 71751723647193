/**
 * Copyright 2015-present Isshoikka. All Rights Reserved.
 *
 */

const IsshoikkaArrangementActionConstants = {
    LOAD_ALL: "load_all",
    LOAD_BY_ID: "load_by_id",
};

export default IsshoikkaArrangementActionConstants;
